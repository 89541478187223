import { create } from 'zustand';
import api from '../Api';

const useUserStore = create((set, get) => ({
    user: null,
    userProfile: null,
    isLoading: false,
    error: null,
    buId: null,
    settings: null,

    setBuId: (buId) => set({ buId }),
    setBuId: (buId) => set({ buId }),
    setUserQuizList: (userQuizList) => set({ userQuizList }),
    setIsLoading: (isLoading) => set({ isLoading }),

    fetchUser: async () => {
        set({ isLoading: true, error: null });
        try {
            const token = localStorage.getItem('jwt');
            const response = await api.fetchUser(token);
            set({ user: response, isLoading: false });
        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },

    fetchProfile: async () => {
        const { user, fetchUser } = get();
        if (!user) await fetchUser();

        const { user: updatedUser } = get();
        if (updatedUser) {
            set({ isLoading: true, error: null });
            try {
                const token = localStorage.getItem('jwt');
                const response = await api.fetchProfile(token, updatedUser.id);
                const profile = response.results[0];
                set({ userProfile: profile, isLoading: false });
                localStorage.setItem('currentProfile', JSON.stringify(profile));
                await get().fetchSettings();
                if (get().hasHRRole(profile)) {
                    await get().fetchBuId();
                }
            } catch (error) {
                set({ error: error.message, isLoading: false });
            }
        }
    },

    fetchBuId: async () => {
        const { userProfile } = get();
        if (!userProfile) return;

        if (get().hasHRRole(userProfile)) {
            set({ isLoading: true, error: null });
            try {
                const token = localStorage.getItem('jwt');
                const response = await api.getBuIdByHrId(token, userProfile.id);
                const buIds = response.results[0]?.items;
                set({ buId: buIds, isLoading: false });
            } catch (error) {
                set({ error: error.message, isLoading: false });
            }
        }
    },

    fetchSettings: async () => {
        set({ isLoading: true, error: null });
        try {
            const token = localStorage.getItem('jwt');
            const response = await api.getSettings(token);
            set({ settings: response, isLoading: false });
        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },

    hasHRRole: (profile) => {
        return profile?.roles?.some(role => role.name === 'HR');
    },

    getCurrentProfile: () => {
        const currentProfile = JSON.parse(localStorage.getItem('currentProfile'));
        if (currentProfile) {
            set({ userProfile: currentProfile });
        }
        return currentProfile;
    },

    initializeStore: async () => {
        await get().fetchProfile();
    }
}));

useUserStore.getState().initializeStore();

export default useUserStore;
