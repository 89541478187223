import React, { useEffect, useRef, useState } from 'react';
import './Header.css';
import Menu from '../Menu/Menu';
import logo_blue from '../../utils/images/pridex_blue-text.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import exit from '../../utils/images/exit.svg';
export default function Header({ isLoggedIn, setIsLoggedIn, onSignOut }) {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <>

            <header className="header">
                <div className='header__block'>
                    <a href='/profile'><img className="header__logo" src={logo_blue} alt='' title='На главную' /></a>
                    {isLoggedIn ? (
                        <>
                            <div style={{display: "flex", flexDirection: "row", alignItems: 'center', gap: '25px'}}>
                                <a className='header__support' href="https://support.pridex.ru" target="_blank"></a>
                                <button className='header__button' onClick={() => onSignOut()}><img src={exit}></img></button>
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </header>

        </>
    );
}
