import useQuizStore from "../../utils/stores/useQuizStore";
import TextareaAutosize from "react-textarea-autosize";

const SummaryEditableQuestionItem = () => {
    const { mergedCalibrationQuiz, handleUpdateComment, handleUpdateAnswer, isDirty, quiz } = useQuizStore();
    console.log(mergedCalibrationQuiz);
    const answerValues = ['5.0', '4.0', '3.0', '1.5', '0.0'];

    const handleCommentChange = (questionId, e) => {
        handleUpdateComment(questionId, e.target.value, 'calibrate');
    };

    return (
        <div className="question-item__div-se">
            <div className="question-item__header">
                <p className="question-item__title">Итоговая оценка</p>
            </div>
            {mergedCalibrationQuiz?.map((question, index) => (
                <>
                    <div key={index} className="question-item__row-se">
                        <div className="question-item__row__scoreh">
                            {question.inspector?.question_name || question.calibrator?.question_name}
                        </div>
                        <div className="question-item__score-select">
                            {['A', 'B', 'C', 'D', 'E'].map((option, idx) => {
                                const isSelected = parseFloat(question?.calibrator?.answer) === parseFloat(answerValues[idx]);
                                const isSelectedByInspector = parseFloat(question?.inspector?.answer) === parseFloat(answerValues[idx]);

                                let buttonClass = 'calibrate-answer';
                                if (isSelected) {
                                    buttonClass = 'calibrate-answer_selected';
                                } else if (isSelectedByInspector) {
                                    buttonClass = 'calibrate-answer_selected-by';
                                }

                                return (
                                    <button
                                        key={option}
                                        className={buttonClass}
                                        type="button"
                                        onClick={() => handleUpdateAnswer(question.calibrator.id_answer, answerValues[idx], 'calibrate')}
                                    >
                                        {option}
                                    </button>

                                );
                            })}
                        </div>
                    </div>
                    {isDirty &&
                        <TextareaAutosize
                            key={`calibr-${question?.calibrator?.question_id}`}
                            type="text"
                            maxRows={5}
                            className='question-item__input'
                            placeholder='Введите комментарий...'
                            value={question?.calibrator?.comment || ''}
                            onChange={(e) => handleCommentChange(question?.calibrator?.question_id, e)}
                        />
                    }
                </>
            ))}
        </div>
    );
}

export default SummaryEditableQuestionItem;