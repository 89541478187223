export function formatDate(dateInput) {
    if(!dateInput) return;

    let dateObj;
    if (typeof dateInput === 'string' || dateInput instanceof String) {
        dateObj = new Date(dateInput);
    } else if (dateInput instanceof Date) {
        dateObj = dateInput;
    } else {
        console.log("Invalid date input");
    }

    const months = [
        "января", "февраля", "марта", "апреля", "мая", "июня",
        "июля", "августа", "сентября", "октября", "ноября", "декабря"
    ];
    const day = dateObj.getUTCDate();
    const month = months[dateObj.getUTCMonth()];
    const year = dateObj.getUTCFullYear();
    return `${day} ${month} ${year}`;
}


export const getDeadlineDate = (date, dayAmount) => {

    const creationDate = new Date(date);
    const deadlineDate = new Date(creationDate);
    deadlineDate.setDate(creationDate.getDate() + dayAmount || 14);
    return deadlineDate.toString();
}



export const getDeadlineDateString = (time_create, dayAmount) => {
    const creationDate = new Date(time_create);
    const deadlineDate = new Date(creationDate.getTime() + dayAmount * 24 * 60 * 60 * 1000);

    const today = new Date();
    const timeDiff = deadlineDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    const getDaySuffix = (days) => {
        if (days % 10 === 1 && days % 100 !== 11) return 'день';
        if ([2, 3, 4].includes(days % 10) && ![12, 13, 14].includes(days % 100)) return 'дня';
        return 'дней';
    };

    const fullText = daysLeft === 0
        ? 'Последний день оценки'
        : daysLeft < 0
        ? 'Анкета просрочена'
        : `до ${formatDate(deadlineDate)} (осталось ${daysLeft} ${getDaySuffix(daysLeft)})`;

    const shortText = daysLeft === 0
        ? 'Последний день'
        : daysLeft < 0
        ? 'Просрочено'
        : `Осталось ${daysLeft} дней`;

    return { fullText, shortText };
};


export const convertDateToQuarter = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const quarter = ['I', 'II', 'III', 'IV'][Math.floor(date.getMonth() / 3)];
    return <p className='score-module__item__date'><strong>{quarter}&nbsp;квартал</strong> {year}</p>;
};

export function getQuarterDate(quarter) {
    const currentYear = new Date().getFullYear();
    const quarterDates = {
        1: '-03-31',
        2: '-06-30',
        3: '-09-30',
        4: '-12-15'
    };
    const date = `${currentYear}${quarterDates[quarter]}`;

    return date;
}